import { isEntryOrAsset } from './isEntryOrAsset';
import type { IAnimation, IImage, IVideo } from '~/lib/ContentfulService';

export type IMedia = IAnimation | IImage | IVideo;

export type MediaTypeInterface = {
  image: IImage;
  video: IVideo;
  animation: IAnimation;
};

export function isMediaType<T extends keyof MediaTypeInterface>(
  value: unknown,
  mediaType: T
): value is MediaTypeInterface[T] {
  return isEntryOrAsset(value) && mediaType === value.sys.contentType?.sys.id;
}
